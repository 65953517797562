import { render, staticRenderFns } from "./resource-json.vue?vue&type=template&id=ef14df22&scoped=true&"
import script from "./resource-json.vue?vue&type=script&lang=js&"
export * from "./resource-json.vue?vue&type=script&lang=js&"
import style0 from "./resource-json.vue?vue&type=style&index=0&id=ef14df22&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef14df22",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VSkeletonLoader,VTextField})
